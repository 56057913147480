/////////////////////////////////
//Header
.navigation{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
	&__item{
		&:first-child{
			margin-right: auto;
		}
		&:not(:first-child){
			margin-left:3.6rem;
			@include phone{
				margin-left:2rem;
			}
		}
	}
	&__link,
	&__link:link,
	&__link:visited{
		color: var(--color-light);
		font-size:1.6rem;
		font-weight: 600;
		text-decoration: none;
		// text-shadow: var(--text-shadow);
		text-transform: uppercase;
		@include phone{
			font-size: 1.4rem;
		}
		&:last-child{
			margin-right:0;
		}
		&.active,
		&:hover{
			color: var(--color-base);
		}
	}
	&__link-main,
	&__link-main:visited,
	&__link-main:link{
		font-size:2.4rem;
		color: var(--color-base);
		@include phone{
			font-size: 1.8rem;
		}
	}
}

/////////////////////////////////
//INTRO
.content{
	@include flex-center;
	flex-direction: column;
	padding: 4.8rem;
	z-index: 10;
	&__paragraph{
		font-size: 2.4rem;
		text-transform: uppercase;
		@include phone{
			font-size: 2rem;
		}
	}
}

/////////////////////////////////
//PORTFOLIO
.portfolio{
	@include flex-center;
	flex-direction: column;
	z-index: 10;
	padding: 4.8rem;
	@include phone{
		padding: 2.4rem 0 0 0 ;
		flex: 1;
		.heading-primary{
			margin-bottom: 1.6rem;
		}
	}
	&__container{
		height: auto;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-wrap: wrap;
		@include phone{
			width: 100%;
			flex:1;
			height:auto;
		}
	}
}

/////////////////////////////////
//CARD
.card{
	// flex: 0 0 50%;
	height: 31rem;
	position: relative;
	width: 410px;
	transition: all .3s;
	@include tablet-small{
		height: 340px;
	}
	@include phone{
		@include flex-center;
		width: 100%;
		flex-direction: column;
		height: auto;
	}
	&__front,
	&__back{
		position: absolute;
		top:0;
		left:0;
		height: 31rem;
		transition: all .3s;
		@include tablet-small{
			height: 340px;
		}
		@include phone{
			position: unset;
			height: auto;
		}
	}

	&__back{
		width: 100%;
		padding: 4.8rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		background-color: #fff;
		opacity:0;
		visibility:hidden;
		position: relative;
		@include phone{
			opacity:1;
			visibility:visible;

		}
		&--top{
			text-align: center;
			position: absolute;
			top: 0;
			opacity: 0;
			transition: all .3s ease-in;
			@include phone{
				opacity:1;
				visibility:visible;
				position: unset;
				margin-bottom: 3.6rem;
			}
		}
		&--bottom{
			position: absolute;
			bottom:0;
			opacity: 0;
			transition: all .3s ease-in;
			@include phone{
				opacity:1;
				visibility:visible;
				position: unset;
			}
		}
	}
	&:hover &__back{
		opacity:1;
		visibility: visible;
		&--top{
			top: 25%;
			opacity: 1;
		}
		&--bottom{
			bottom:25%;
			opacity: 1;
		}
	}

	&__image{
		width:100%;
		display: flex;
	}	

	&__title{
		font-size: 2.8rem;
	}	

	&__text{
		font-size: 1.6rem;
		// color: var(--color-accent-1);
	}

	&__btn{
		padding: .8rem 1.6rem;
		font-size: 1.6rem;
		color: var(--color-base);
		transition: all .2s ease-in;
		cursor:pointer;
		@include phone{
			background-color: #fff;
		}
	}

	&__btn:hover{
		color: #fff;
	}
	
	&__btn:focus{
		outline: none;
	}
}

/////////////////////////////////
//PROJECT
.project{
	@include flex-center;
	flex-direction: column;
	padding: 4.8rem;
	font-size: 1.6rem;	
	position: relative;
	// text-align: center;
	@include tablet-small{
		padding: 2.4rem 0;		
	}
	&__container{
		@include flex-center;
		flex-direction: column;
		margin-bottom: 8rem;
		padding-bottom: 8rem;
		border-bottom: 1px solid;
	}	

	&__image{
		width: 100%;
		margin-bottom: 3.6rem;
		@include phone{
			width:auto;
		}
	}

	&__tags{
		flex: 0 0 100%;
		align-self:stretch;
		@include tablet-small{
			padding: 0 2.4rem;		
		}
	}

	&__list{
		list-style: none;
		display: flex;
		@include phone{
			flex-wrap:wrap;
		}
	}

	&__item{
		margin: .8rem;
		padding: 2px .8rem;
		background-color: var(--color-accent-2);
		color:#fff;
	}

	&__description{
		margin-bottom: 3.6rem;
		align-self:stretch;
		@include tablet-small{
			padding: 0 2.4rem;		
		}
		&-paragraph{
			margin: 1.6rem;
		}
		&-link,
		&-link:link,
		&-link:visited{
			margin: 1.6rem;
			padding-bottom: 3px;
			text-decoration: none;
			color:var(--color-base);
			border-bottom: 1px solid;
			transition: color .2s;
			font-weight: 600;
			&:hover{
				& span{
					margin-left: 8px;
				}
			}
			& span {
				transition: margin-left .2s;
			}
		}
	}
	// .heading-secondary{
	// 	position: relative;
	// }
	// .heading-secondary::before{
	// 	content:"";
	// 	position: absolute;
	// 	border-bottom:2px solid;
	// 	width:35px;
	// 	bottom:-5px;
	// }
}

/////////////////////////////////
//ABOUT
.about{
	@include flex-center;
	flex-direction: column;
	padding: 4.8rem;
	@include phone{
		padding: 2.4rem 0;		
	}
	&__photo{
		margin-bottom: 3.6rem;
		width: 100%;
		
	}

	&__paragraph{
		font-size: 1.6rem;
		@include phone{
			padding: 0 2.4rem;
		}
	}
	.heading-secondary{
		width: 100%;
		@include phone{
			padding: 0 2.4rem;
			font-size: 2rem;
		}
	}
}



/////////////////////////////////
//FOOTER
.footer{
	@include flex-center;
	z-index:10;
	width: 100%;
	height: 100%;
	flex-wrap: wrap;
	&__link-box{
		margin: 0 1.5rem;
		&:last-child{
			flex: 0 0 100%;
			text-align: center;
			font-size: 1.4rem;
		}
	}
	&__link{
		text-decoration: none;
		color: var(--color-base);
		flex-wrap:wrap;

		&.instagram::before,
		&.facebook::before,
		&.twitter::before,
		&.linkedin::before,
		&.github::before{
			content:'';
			display:inline-block;
			background-repeat: no-repeat;
			height:18px;
			width:18px;
			@include phone(){
				height:14px;
				width:14px;
				background-size: 100%;
			}
		}

		
		&.instagram::before{
			background-image: url("../../../public/images/Instagram.svg");
		}
		&.facebook::before{
			background-image: url("../../../public/images/FB.svg");
		}
		&.twitter::before{
			background-image: url("../../../public/images/Twitter.svg");
		}
		&.linkedin::before{
			background-image: url("../../../public/images/Linkedin.svg");
		}
		&.github::before{
			background-image: url("../../../public/images/Github.svg");
		}
	}
}