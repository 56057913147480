
@mixin phone {
	@media only screen and (max-width : 480px) {	
		@content;
	}
}

@mixin tablet-small {
	@media only screen and (max-width: 600px) {	
		@content;
	}
}

@mixin tablet {
	@media only screen and (max-width: 900px) {
		@content;
	}
}

@mixin centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}